import React, { Component } from 'react';
import { css } from '@emotion/react'

import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import TextField from './../elements/TextField'
import IconButton from './../elements/IconButton'
import InteractiveList from './../elements/InteractiveList'
import { CataloguesService } from '../../services/LandingV4'

// import { Snackbar, Backdrop, Checkbox, FormGroup, MenuItem, Grid, Select, FormControl, InputLabel, Box, Container, Radio, RadioGroup, FormControlLabel, FormLabel } from "@material-ui/core"
import { Alert } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';


//MATERIAL UI COMPONENTS
import { Grid, Container, Backdrop, Snackbar, Box } from "@material-ui/core"
import {
    CardElevation,
    Sliderv3, TitleIcon, MaterialInput
} from '@findep/mf-landings-core'
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import DateMobile from "../../components/elements/DateMobile"

import Tabs from '../elements/TabsGeneric'
const search = css`

  form.example input[type=text] {
    padding: 10px;
    font-size: 17px;
    border: 1px solid grey;
    float: left;
    width: 80%;
    background: #f1f1f1;
  }
  
  form.example button {
    float: left;
    width: 20%;
    padding: 10px;
    background: #2196F3;
    color: white;
    font-size: 17px;
    border: 1px solid grey;
    border-left: none;
    cursor: pointer;
  }
  
  form.example button:hover {
    background: #0b7dda;
  }
  
  form.example::after {
    content: "";
    clear: both;
    display: table;
  }
  `

const horizontal = css`
  display: flex;        
  justify-content: center;
  `
const bDrop = css`
  z-index:9999;
  color: '#fff';
`
const catalogues = new CataloguesService();
class BusquedaAvanzada extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: 0,
            loader: false,
            errorService: false,
            campaniacurp: "",
            campaniarfc: "",
            dataTable: [],
            data: "",
            btnDisabled: true,
            primernombre: "",
            segundonombre: "",
            apepat: "",
            apemat: "",
            fechanac: "",
            nombre: ""
        }
        this.tabsEvent = this.tabsEvent.bind(this)
        this.buscarRfc = this.buscarRfc.bind(this)
        this.buscarCurp = this.buscarCurp.bind(this)
        this.handleChange = this.handleChange.bind(this)

    }

    tabsEvent = (valor) => {
        this.setState({
            tab: valor
        })
    }

    buscarRfc = async (valor) => {
        let resultadosRFC = []
        window.dataLayer = window.dataLayer || [];
        try {
            this.setState({ loader: true })
            const { data } = await catalogues.getRenovaRfc(valor)
            this.setState({ data })
            data.map((row, index) => {
                return resultadosRFC.push(`${index}_${row.tenantId}`)
            })
            dataLayer.push({
                'event': 'consultaRealizada',
                'eventCategory': 'Renovaciones',
                'eventAction': 'Buscar_RFC',
                'eventLabel': `[${resultadosRFC.toString()}]`
            })
            this.setState({ loader: false })
        } catch (error) {
            console.log(error)
            this.setState({ loader: false })
            this.setState({ errorService: true })
            this.setState({ data: [] })
        }
    }

    buscarNombre = async () => {
        const { primernombre, segundonombre, apepat, apemat, fechanac } = this.state
        let resultadosRFC = []
        try {
            this.setState({ loader: true })
            let nombrecompuesto = ""
            if (primernombre.length > 0 && segundonombre.length > 0) {
                nombrecompuesto = `${primernombre} ${segundonombre}`
            } else if (primernombre.length > 0) {
                nombrecompuesto = `${primernombre}`
            } else if (segundonombre.length > 0) {
                nombrecompuesto = `${segundonombre}`
            }
            this.setState({ nombre: nombrecompuesto })
            const { data } = await catalogues.getRenovaNombre(nombrecompuesto, apepat, apemat, fechanac)
            this.setState({ data })
            this.setState({ loader: false })
        } catch (error) {
            console.log(error)
            this.setState({ loader: false })
            this.setState({ errorService: true })
            this.setState({ data: [] })
        }
    }

    buscarCurp = async (valor) => {

        let resultadosCURP = []

        window.dataLayer = window.dataLayer || [];
        try {
            this.setState({ loader: true })
            const { data } = await catalogues.getRenovaCurp(valor)
            this.setState({ data })
            data.map((row, index) => {
                return resultadosCURP.push(`${index}_${row.tenantId}`)
            })
            dataLayer.push({
                'event': 'consultaRealizada',
                'eventCategory': 'Renovaciones',
                'eventAction': 'Buscar_CURP',
                'eventLabel': `[${resultadosCURP.toString()}]`
            })
            this.setState({ loader: false })
        } catch (error) {
            console.log(error)
            this.setState({ data: [] })
            this.setState({ loader: false })
            this.setState({ errorService: true })
        }
    }


    handleChange(prop, data) {
        if (prop === 'campaniacurp') {
            this.setState({
                campaniacurp: data.value.toUpperCase()
            })
        }
        if (prop === 'campaniarfc') {
            this.setState({
                campaniarfc: data.value.toUpperCase()
            })
        }
    }

    componentDidUpdate(prevProp, prevState) {
        if (prevState.data !== this.state.data) {
            this.setState({
                dataTable: this.state.data
            })
        }
        if (this.state.tab === 0 && prevState.campaniacurp !== this.state.campaniacurp && this.state.campaniacurp.length === 18) {
            this.setState({
                btnDisabled: false
            })
        }

        if (this.state.tab === 1 && prevState.campaniarfc !== this.state.campaniarfc && (this.state.campaniarfc.length === 12 || this.state.campaniarfc.length === 13)) {
            this.setState({
                btnDisabled: false
            })
        }
        if (this.state.tab === 0 && prevState.campaniacurp !== this.state.campaniacurp && (this.state.campaniacurp === "" || this.state.campaniacurp.length < 18)) {
            this.setState({
                btnDisabled: true
            })
        }
        if (this.state.tab === 1 && prevState.campaniarfc !== this.state.campaniarfc && (this.state.campaniarfc === "" || this.state.campaniarfc.length < 13 || this.state.campaniarfc.length < 12)) {
            this.setState({
                btnDisabled: true
            })
        }

        const { tab, primernombre, apepat, fechanac } = this.state;
        if (
            tab === 2 && primernombre !== "" && apepat !== "" && fechanac !== "" && 
            (tab !== prevState.tab || primernombre !== prevState.primernombre || apepat !== prevState.apepat || fechanac !== prevState.fechanac)
        ) {
            this.setState({
                btnDisabled: false
            });
        }

        if (
            tab === 2 && (primernombre === "" || apepat === "") &&
            (tab !== prevState.tab || primernombre !== prevState.primernombre || apepat !== prevState.apepat)
        ) {
            this.setState({
                btnDisabled: true
            });
        }
    }



    render() {
        return (
            <>
                <CardElevation>
                    <Grid container justify="center">
                        <Grid item style={{ textAlign: "center" }}>
                            <TitleIcon icon={SwitchVideoIcon} title="Buscar Por:" size={20} textColor />
                        </Grid>
                    </Grid>
                    <br />

                    <Grid container
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Tabs onClick={this.tabsEvent} />
                        </Grid>
                        <Grid item xs={2}></Grid>
                        {this.state.tab === 0 && <>
                            <Grid item xs={7}>
                                <br />
                                <MaterialInput
                                    id="tus-datos-apellido-paterno"
                                    type="text"
                                    label="Ingresa tú curp"
                                    value={this.state.campaniacurp}
                                    // pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                                    // accept=" A-ZÑÁÉÍÓÚÜ"
                                    // transform={(value) => value.toUpperCase().slice(0, 50)}
                                    // onError={(error) => this._handleError('apellidoPaterno', error)}
                                    // errorMessage={this.state._errors.apellidoPaterno.errorType}
                                    onChange={(_event, component) => this.handleChange('campaniacurp', component)}
                                    required={true}
                                    // error={this.state._errors.apellidoPaterno.error}
                                    // disabled={this.state._errors.nombre.error || this.state.nombre === ''}
                                    helperText="Campo obligatorio"
                                />
                            </Grid>
                            <Grid item xs={1} >
                                <IconButton disabled={this.state.btnDisabled} onClick={() => { this.buscarCurp(this.state.campaniacurp) }} />
                            </Grid>
                        </>
                        }
                        {this.state.tab === 1 &&
                            <><Grid item xs={7}>
                                <br />
                                <MaterialInput
                                    id="tus-datos-apellido-paterno"
                                    type="text"
                                    label="Ingresa tú rfc"
                                    value={this.state.campaniarfc}
                                    // pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                                    // accept=" A-ZÑÁÉÍÓÚÜ"
                                    // transform={(value) => value.toUpperCase().slice(0, 50)}
                                    // onError={(error) => this._handleError('apellidoPaterno', error)}
                                    // errorMessage={this.state._errors.apellidoPaterno.errorType}
                                    onChange={(_event, component) => this.handleChange('campaniarfc', component)}
                                    required={true}
                                    // error={this.state._errors.apellidoPaterno.error}
                                    // disabled={this.state._errors.nombre.error || this.state.nombre === ''}
                                    helperText="Campo obligatorio"
                                />
                            </Grid>
                                <Grid item xs={1} >
                                    <IconButton disabled={this.state.btnDisabled} onClick={() => { this.buscarRfc(this.state.campaniarfc) }} />
                                </Grid>
                            </>}
                        {this.state.tab === 2 &&
                            <>
                                <Grid item xs={7}>
                                    <Grid container
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item xs={12}><br /></Grid>
                                        <Grid item xs={6}>
                                            <MaterialInput
                                                id="tus-datos-primernombre"
                                                type="text"
                                                label="Primer nombre"
                                                value={this.state.primernombre}
                                                // pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                                                // accept=" A-ZÑÁÉÍÓÚÜ"
                                                // transform={(value) => value.toUpperCase().slice(0, 50)}
                                                // onError={(error) => this._handleError('apellidoPaterno', error)}
                                                // errorMessage={this.state._errors.apellidoPaterno.errorType}
                                                onChange={(_event, component) => this.setState({ primernombre: component.value })}
                                                required={true}
                                                // error={this.state._errors.apellidoPaterno.error}
                                                // disabled={this.state._errors.nombre.error || this.state.nombre === ''}
                                                helperText="Campo obligatorio"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MaterialInput
                                                id="tus-datos-segundonombre"
                                                type="text"
                                                label="Segundo nombre"
                                                value={this.state.segundonombre}
                                                // pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                                                // accept=" A-ZÑÁÉÍÓÚÜ"
                                                // transform={(value) => value.toUpperCase().slice(0, 50)}
                                                // onError={(error) => this._handleError('apellidoPaterno', error)}
                                                // errorMessage={this.state._errors.apellidoPaterno.errorType}
                                                onChange={(_event, component) => this.setState({ segundonombre: component.value })}
                                                required={false}
                                                // error={this.state._errors.apellidoPaterno.error}
                                                // disabled={this.state._errors.nombre.error || this.state.nombre === ''}
                                                helperText="Campo opcional"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MaterialInput
                                                id="tus-datos-apepat"
                                                type="text"
                                                label="Apellido paterno"
                                                value={this.state.apepat}
                                                // pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                                                // accept=" A-ZÑÁÉÍÓÚÜ"
                                                // transform={(value) => value.toUpperCase().slice(0, 50)}
                                                // onError={(error) => this._handleError('apellidoPaterno', error)}
                                                // errorMessage={this.state._errors.apellidoPaterno.errorType}
                                                onChange={(_event, component) => this.setState({ apepat: component.value })}
                                                required={true}
                                                // error={this.state._errors.apellidoPaterno.error}
                                                // disabled={this.state._errors.nombre.error || this.state.nombre === ''}
                                                helperText="Campo obligatorio"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MaterialInput
                                                id="tus-datos-apemat"
                                                type="text"
                                                label="Apellido materno"
                                                value={this.state.apemat}
                                                // pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                                                // accept=" A-ZÑÁÉÍÓÚÜ"
                                                // transform={(value) => value.toUpperCase().slice(0, 50)}
                                                // onError={(error) => this._handleError('apellidoPaterno', error)}
                                                // errorMessage={this.state._errors.apellidoPaterno.errorType}
                                                onChange={(_event, component) => this.setState({ apemat: component.value })}
                                                required={false}
                                                // error={this.state._errors.apellidoPaterno.error}
                                                // disabled={this.state._errors.nombre.error || this.state.nombre === ''}
                                                helperText="Campo opcional"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DateMobile handleChangeFecha={(fechadate) => this.setState({ fechanac: fechadate })} />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <IconButton disabled={this.state.btnDisabled} onClick={() => { this.buscarNombre() }} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </>}
                        <Grid item xs={2}></Grid>
                        <Grid item xs={10} >
                            <br />
                            <br />

                            <InteractiveList data={this.state.dataTable} busqueda={this.state.tab} />
                        </Grid>
                    </Grid>
                </CardElevation>
                <Backdrop css={bDrop} open={this.state.loader} >
                    <CircularProgress color="primary" />
                </Backdrop>
                <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
                    <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
                        Lo sentimos, por el momento no cuenta con una Oferta.
                    </Alert>
                </Snackbar>
            </>
        );
    }
}

export default BusquedaAvanzada;



